@mixin schedule() {

    .scheduled_date {
        background-color: rgb(241, 165, 73);
        border-radius: 10px;
        margin: 5px;
        padding: 3px;
        text-align: center;
        cursor: pointer;
    }

    #passed {
        .scheduled_date {
            cursor: not-allowed;
            background-color: rgb(133, 91, 39);
        }
    }

    #scheduled_date_modal {
        display: none;
    }

    #mask {
        background: rgba(0, 0, 0, 0.4);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;

        &.hidden {
            display: none;
        }
    }

    #open,
    .close {
        cursor: pointer;
        width: 200px;
        border: 1px solid #ccc;
        border-radius: 4px;
        text-align: center;
        padding: 12px;
        margin: 16px auto 0;
        background: #4caf50;
        color: white;
        top: 30%;
    }

    #modal221009,
    #modal221028,
    #modal221103 {
        background: #fff;
        color: #555;
        width: 300px;
        padding: 40px;
        border-radius: 4px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        margin: 0 auto;
        z-index: 2;
        transition: 0.4s;

        &.hidden {
            display: none;
        }
    }

}