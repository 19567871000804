@use "./_header.scss" as header;
@use "./_introduction.scss" as introduction;
@use "./_form.scss" as form;
@use "./_links.scss" as links;
@use "./schedule.scss" as schedule;
@use "./opinion.scss" as opinion;

@tailwind base;
@tailwind components;
@tailwind utilities;


h2 {
    @apply text-2xl font-bold text-center;
    margin-top: 20px;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    padding: 5px;

    * {
        max-width: 720px;
    }
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        /* animation: App-logo-spin infinite 20s linear; */
    }
}

.App-header {
    background-color: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include header.header;
    @include introduction.introduction;
    @include form.form;
    @include links.links;
    @include schedule.schedule;
    @include opinion.opinion;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}