@mixin form() {
    .Form {
        form {
            background-color: rgb(170, 88, 0);
            padding: 10px;
            border-radius: 5px;
            color: #fff;
            max-width: 720px;

            p {
                text-align: left;
                line-height: 30px;
                padding: 5px 0px;

                input {
                    width: 100%;
                    border-radius: 5px;
                    color: #000;
                    padding: 5px;
                }

                &.register_btn {
                    text-align: center;
                    margin-top: 10px;

                    input {
                        background-color: #fff;
                        color: rgb(0, 0, 0);
                        border: none;
                        padding: 5px;
                        font-size: 1.2rem;
                        font-weight: bold;
                        cursor: pointer;
                        width: 50%;

                        &:hover {
                            background-color: rgb(255, 0, 0);
                            color: #fff;
                        }
                    }
                }
            }

        }
    }
}