@tailwind base;
@tailwind components;
@tailwind utilities;
h2 {
  @apply text-2xl font-bold text-center;
  margin-top: 20px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  padding: 5px;
}
.App * {
  max-width: 720px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}
.App-header {
  background-color: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body header {
  padding: 10px;
}
body #description {
  margin: 10px;
  padding: 2rem 0;
  text-align: center;
}
body .Form form {
  background-color: rgb(170, 88, 0);
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  max-width: 720px;
}
body .Form form p {
  text-align: left;
  line-height: 30px;
  padding: 5px 0px;
}
body .Form form p input {
  width: 100%;
  border-radius: 5px;
  color: #000;
  padding: 5px;
}
body .Form form p.register_btn {
  text-align: center;
  margin-top: 10px;
}
body .Form form p.register_btn input {
  background-color: #fff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  width: 50%;
}
body .Form form p.register_btn input:hover {
  background-color: rgb(255, 0, 0);
  color: #fff;
}
body p {
  width: 100%;
}
body p a {
  border: 1px solid #ccc;
  padding: 5px;
  line-height: 40px;
  width: 100%;
}
body p a:hover {
  background-color: rgb(170, 88, 0);
  color: #fff;
}
body p .banners {
  border: none;
  padding: 0;
  line-height: 0;
}
body p .banners img {
  width: 100%;
}
body p.streaming {
  display: none;
  -webkit-animation: flash 0.1s linear infinite;
          animation: flash 0.1s linear infinite;
  color: #f00;
  font-size: 20px;
  text-align: center;
}
@-webkit-keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
body .scheduled_date {
  background-color: rgb(241, 165, 73);
  border-radius: 10px;
  margin: 5px;
  padding: 3px;
  text-align: center;
  cursor: pointer;
}
body #passed .scheduled_date {
  cursor: not-allowed;
  background-color: rgb(133, 91, 39);
}
body #scheduled_date_modal {
  display: none;
}
body #mask {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
body #mask.hidden {
  display: none;
}
body #open,
body .close {
  cursor: pointer;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  padding: 12px;
  margin: 16px auto 0;
  background: #4caf50;
  color: white;
  top: 30%;
}
body #modal221009,
body #modal221028,
body #modal221103 {
  background: #fff;
  color: #555;
  width: 300px;
  padding: 40px;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 2;
  transition: 0.4s;
}
body #modal221009.hidden,
body #modal221028.hidden,
body #modal221103.hidden {
  display: none;
}
body .opinion {
  background-color: #fff;
  border-color: rgb(170, 88, 0);
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  color: #000;
  max-width: 720px;
  margin-top: 10px;
  cursor: pointer;
  margin: 20px 0;
  text-align: center;
}
body .opinion_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body .opinion_modal form {
  max-width: 500px;
}
body .opinion_modal input {
  width: 100%;
  border-radius: 5px;
  height: 2em;
  border-color: #000;
  border: 1px solid;
  color: #000;
  padding: 5px;
  margin: 5px 0;
}
body .opinion_modal textarea {
  width: 100%;
  border-radius: 5px;
  height: 3em;
  border-color: #000;
  border: 1px solid;
  color: #000;
  padding: 5px;
  margin: 5px 0;
}
body .opinion_modal input[type=submit] {
  background-color: #fff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  width: 50%;
  margin: 10px auto;
  display: block;
  border-radius: 5px;
  border: 1px solid #000;
}
body .opinion_modal input[type=submit]:hover {
  background-color: rgb(170, 88, 0);
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}/*# sourceMappingURL=index.css.map */