@mixin opinion() {
    .opinion {
        background-color: #fff;
        border-color: rgb(170, 88, 0);
        border: 1px solid;
        padding: 10px;
        border-radius: 5px;
        color: #000;
        max-width: 720px;
        margin-top: 10px;
        cursor: pointer;
        margin: 20px 0;
        text-align: center;
    }

    .opinion_modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        form {

            max-width: 500px;
        }

        input {
            width: 100%;
            border-radius: 5px;
            height: 2em;
            border-color: #000;
            border: 1px solid;
            color: #000;
            padding: 5px;
            margin: 5px 0;
        }

        textarea {
            width: 100%;
            border-radius: 5px;
            height: 3em;
            border-color: #000;
            border: 1px solid;
            color: #000;
            padding: 5px;
            margin: 5px 0;
        }

        input[type="submit"] {
            background-color: #fff;
            color: rgb(0, 0, 0);
            border: none;
            padding: 5px;
            font-size: 1.2rem;
            font-weight: bold;
            cursor: pointer;
            width: 50%;
            margin: 10px auto;
            display: block;
            border-radius: 5px;
            border: 1px solid #000;

            &:hover {
                background-color: rgb(170, 88, 0);
                color: #fff;
            }
        }
    }

    ;
}